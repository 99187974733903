// Styles for table within PriceOptions component
.table {
    border-collapse: separate;
    border-spacing: 0 15px; // Add spacing between rows
    width: 100%;
    
    th, td {
      text-align: left;
      padding: 12px;
      //border-radius: 8px; // Slightly rounded corners for each cell
      background-color: #f5f5f5; // Light grey background for table cells
      //box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Soft shadow for a floating effect
    }
  
    th {
      font-weight: bold;
      color: #4a4a4a; // Dark grey text for table headers
      background-color: #e0e0e0; // Darker grey for headers
    }
  
    td {
      color: #333333; // Dark grey text for table data
      background-color: #f5f5f5; // Slightly off-white background for the data cells
      transition: background-color 0.3s ease, box-shadow 0.3s ease;
  
      &:hover {
        background-color: #e0e0e0; // Slightly darker grey on hover
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); // More pronounced shadow on hover
      }
    }
  
    .btn {
      //border-radius: 50%; // Make buttons round
      background-color: #656565; // Dark grey background for buttons
      color: white;
  
      &:hover {
        background-color: #6d6d6d; // Slightly lighter on hover
      }
    }
  
    .form-control, .form-select {
      border: none;
      border-radius: 6px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Soft shadow for inputs
      background-color: #ffffff; // White background for inputs
      color: #4a4a4a; // Grey text color for input fields
  
      &:focus {
        border: 1px solid #6d6d6d; // Darker border on focus
        outline: none;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15); // Glow effect on focus
      }
    }
  
    .p-inputgroup {
      margin-top: 10px; // Space between quantity and unit input pairs
    }
  
    // Expandable details row
    .container {
      background-color: #f9f9f9; // Light background for expanded details section
      padding: 15px;
      border-radius: 8px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Shadow for the expanded section
    }
  }
  