.fc .fc-daygrid-day {
  background-color: #ffffff; /* Match the widget background */
  border: none;
  border-radius: 10px;
  margin: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Same shadow as widget */
}

.fc .fc-daygrid-day-top {
  color: #333; /* Subtle date numbers */
  font-weight: 600; /* Slightly bolder to match the design */
}

.fc .fc-day-today {
  background-color: #FFEBEE; /* Soft pink background for today to match the concept */
  border-left: 4px solid #9920be; /* Left border highlight for today */
}

.fc .fc-event {
  background-color: #F8BBD0; /* Soft pink color for the events to match the design */
  color: #AD1457; /* Contrasting text color for legibility */
  border: none; /* Remove borders for a cleaner look */
  border-radius: 8px; /* Rounded corners for the events */
}

.fc .fc-event:hover {
  background-color: #F48FB1; /* Slightly darker on hover for interactivity */
}

.fc .fc-toolbar {
  background-color: transparent; /* Transparent background for the header */
  color: #AD1457; /* Theme color for the text to match the design */
  padding: 0; /* Remove padding */
  margin-bottom: 20px; /* Space below the header */
}

.fc .fc-toolbar-title {
  font-size: 1.5em; /* Larger title to match the design */
  font-weight: 700; /* Bolder font weight for the title */
}

.fc .fc-button-primary {
  background-color: #9920be; /* Button color to match the design */
  color: white; /* White text color for buttons */
  border: none; /* Remove border */
  border-radius: 20px; /* Rounded buttons */
  padding: 8px 16px; /* Padding for buttons */
}

.fc .fc-button-primary:hover {
  background-color: #d342ff; /* Slightly darker on hover */
}

/* Adjust the day cell sizes for a more spacious layout */
.fc .fc-daygrid-day {
  padding: 16px; /* More padding inside each day cell */
  min-height: 120px; /* Minimum height to accommodate more events */
}


/* Add more custom styles as needed */


/* Header row styles */
.fc .fc-col-header-cell {
  background-color: #ffffff; /* Match the widget background */
  color: #AD1457; /* Theme color for the text to match the design */
  font-size: 1rem; /* Adjust to match design */
  font-weight: 700; /* Bolder font weight for the header */
  padding: 10px 0; /* Adjust padding to match design */
  border-radius: 10px; /* Rounded corners for the header cells */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Same shadow as widget */
}

/* Header cell borders */
.fc .fc-col-header-cell {
  border: none; /* Remove default borders */
}

/* Adjust header cell text alignment and padding */
.fc .fc-col-header-cell .fc-scrollgrid-sync-inner {
  justify-content: center; /* Center the text within the cell */
}

/* Style for the current day in header */
.fc .fc-col-header-cell.fc-day-today {
  background-color: #FFEBEE; /* Soft pink background for today */
  border-left: 4px solid #9920be; /* Left border highlight for today */
}

/* Adjust the header buttons to match the stats-widget style */
.fc .fc-button {
  background-color: #9920be; /* Button color to match the design */
  color: white; /* White text color for buttons */
  border: none; /* Remove border */
  border-radius: 20px; /* Rounded buttons */
  padding: 8px 16px; /* Padding for buttons */
  margin-left: 5px; /* Space between buttons */
}

.fc .fc-button:hover {
  background-color: #d342ff; /* Slightly darker on hover */
}

.fc-event {
  border-color: #00FF00 !important; /* Bright green border */
  background-color: #00FF00 !important; /* Bright green background */
}



/* Add more custom styles as needed */
