@import '~primeflex/primeflex.scss'; // If using PrimeFlex for layout

.dialogContainer {
  background-color: #f5fff9;
}

.bigButton {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
  
  &:hover {
    background-color: #ff6ad5;
    color: #333;
  }

  &:active {
    background-color: #ddd;
    color: #222;
  }

  display: inline-flex; 
  .p-button-label {
    flex-grow: 1;
  }
  .p-button-icon-left {
    margin-right: 0.5rem;
  }
}

.bgindex1 {
  background-color: #ae8bc8;  
}


.bgindex2{
  background-image: linear-gradient(to right, #ee4fffd6, #e6a9ff);
  box-shadow: 1px 2px 12px 0px #8f00ca;
}

.chipindex1{
  background: #991cc2;
  border-radius: 5px;
  color: #eefff6;
  font-weight: 500;
}


.selectedButton {
  background-color: #ff35c7;  
  color: #fff;
  border: 1px solid #ed9fff;

  &:hover {
    background-color: #fc44c8;
    border: 1px solid #ed9fff;
  }

  &:active {
    background-color: #ff35c7;
    border: 1px solid #ed9fff;
  }
}

.cardHover {
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #ff6ad5;
    color: #333;
  }

  &:active {
    background-color: #ddd;
    color: #222;
  }
}

.p-highlight {  
  background-color: #364e41;
}


.selectButtonCustom {
  width: 100%; // Ensure the button takes full width of the column
  height: 7rem; // Fixed height
  transition: background-color 0.3s, color 0.3s;
  margin: 0.5rem 0; // Add margin for spacing between buttons
  display: flex;
  justify-content: center;
  align-items: normal;
  overflow: overlay;
  background: #ae8bc8;
  border: 1px solid #e573ff;
  color: #003900;

  &:active, &:hover, &:focus, &.activeButton {
    background-color: #ed9fff;
    border: 1px solid #e573ff;
  }



  .p-button {
    background-color: #ae8bc8; // Set the background color for the button
    color: #fff; // Set the text color

    &:hover {
      background-color: #60d0b4; // Change background on hover
      color: #333; // Change text color on hover
    }

    &:active {
      background-color: #60d0b4; // Change background on active
      color: #222; // Change text color on active
    }
  }

  &.activeButton .p-button {
    background-color: #d6e7de;
    color: #fff;

    &:hover {
      background-color: #b9dbcf;
    }

    &:active {
      background-color: #a0d1c5;
    }
  }
}

.selectButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem; // Add some spacing between buttons
}

.centeredButtonCol {
  display: flex;
  justify-content: center;
  align-items: center;
}


.customDialog {
  height: 100%;
  width: 80vw;
  top: 5%;
  position: absolute;
  border-radius: 15px; /* Add border-radius */
  z-index: 500;
}

