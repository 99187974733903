/* ///var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/css/Header.css  */


.header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures the search bar and icons are on opposite ends */
  padding: 1rem;
  background-image: linear-gradient(to right, #e70afed6, #8217ad, #f4a9aa, #ff7575c7);
  color: #fff; /* Assuming you want white text for the icons and other elements */
  /* The rest of your styles */
}

.back_button {
  color: #7f0098;
  font-size: 40px !important;
}

.header-icons {
  display: flex;
  gap: 1rem; /* Space between icons */
}

.search-bar {
  flex-grow: 1;
  margin-left: 1rem; /* Add space between icons and the search bar */
  margin-right: 1rem; /* Add space between search bar and other elements */
  padding: 0.5rem 1rem;
  border: none; /* Assuming no border as per design */
  border-radius: 20px;
  outline: none;
}

/* Adjust the size and color of the FontAwesome icons if you're using them */
.fas {
  font-size: 1.5rem; /* Example size */
  cursor: pointer; /* Changes cursor to pointer on hover */
}

/* Add additional styles for other header elements */


.p-dialog-center{
  z-index: 500 !important;
}

